/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';
import './styles/colores.scss';
import './styles/fuentes.scss';
import './styles/header.scss';
import './styles/footer.scss';
import './styles/slider.scss';
import './styles/mapaghc.scss';
import './styles/cookies.scss';

const $ = require('jquery');
// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
require('bootstrap');

$('#boton-descargar').show();
$('#boton-noticias').hide();

$(document).ready(function() {
    $('[data-toggle="popover"]').popover();
});



/*$(function(){
    $('.social .texto').hide();
    
    $('li', '.social').hover(function(){
        $(this).addClass([$(this).data('target'), 'expandido']);
        $('.texto', this).stop().show(400);
    }, function(){
        $('.texto', this).stop().hide();
        $(this).removeClass([$(this).data('target'), 'expandido']);
    });
});*/

if (esMoviloTablet()) {
    $('#boton-descargar').hide();
    $('#boton-noticias').show();
} else {
    $('#boton-descargar').show();
    $('#boton-noticias').hide();
}

function esMoviloTablet() {
    return navigator.userAgent.match(/Android/i) ||
           navigator.userAgent.match(/iPad/i) ||
           navigator.userAgent.match(/webOs/i) ||
           navigator.userAgent.match(/iPod/i) ||
           navigator.userAgent.match(/iPhone/i) ||
           navigator.userAgent.match(/Blackberry/i) ||
           navigator.userAgent.match(/Windows Phone/i);
}
